<template>
  <div class="developers">
    <div class="contentWrapper introSection">
      <h1>SDK</h1>
      <p>The TrackIR Enhanced SDK is free for developers to implement, helping to achieve a new level of realism in PC games. Hands-free control opens up new experiences for players to further immerse themselves in the game.</p>
      <p>TrackIR can also be used to augment research and other non-game applications. Simple 6DoF tracking can unlock new levels of control and insight within an experiment, taking your project to the next level.</p>
    </div><!--END contentWrapper-->
      <div class="sdkVersionWrapper">
        <div class="contentWrapper dimBG">
          <div class="visual">
            <img class="sdkLogo" :src="imagesUrl + 'logo-only-unreal-white.svg'" alt="Unreal Engine Logo" />
          </div>
          <div class="copy">
            <h3>SDK For Unreal Engine</h3>
            <p>The TrackIR Enhanced SDK for Unreal Engine includes a TrackIR plugin and an example game. See our <a href="https://docs.trackir.com/trackir-sdk/unreal-engine-quick-start">Quick Start</a> for help getting started.</p>
            <a
              class="btn"
              @click.prevent="eulaDisplayed = true, sdkURL='https://d2mzlempwep3hb.cloudfront.net/TrackIR_SDK/TrackIR_SDK_Unreal_Engine_2.0_Update1.zip'"
              href=""
              >Download</a
            >
            </div>
          </div>
      </div>

      <div class="sdkVersionWrapper">
        <div class="contentWrapper dimBG">
          <div class="visual">
            <img class="sdkLogo" :src="imagesUrl + 'logo-only-unity-white.svg'" alt="Unity Logo" />
          </div>
          <div class="copy">
            <h3>SDK For Unity</h3>
            <p>The TrackIR Enhanced SDK for Unity includes a Unity plugin and an example game. See our <a href="https://docs.trackir.com/trackir-sdk/unity-quick-start">Quick Start</a> for help getting started.</p>
            <a
              class="btn"
              @click.prevent="eulaDisplayed = true, sdkURL='https://d2mzlempwep3hb.cloudfront.net/TrackIR_SDK/TrackIR_SDK_Unity_2.0.zip'"
              href=""
              >Download</a
            >
          </div>
        </div>
      </div>

      <div class="sdkVersionWrapper ">
        <div class="contentWrapper dimBG">
          <div class="visual twoLogos">
            <img class="sdkLogo" :src="imagesUrl + 'logo-python-white.svg'" alt="Python Logo" />
            <img class="sdkLogo" :src="imagesUrl + 'logo-c%2B%2B-white.svg'" alt="C++ Logo" />
          </div>
          <div class="copy">
            <h3>SDK For Python &amp; C++</h3>
            <p>The TrackIR Enhanced SDK for Python and C++ includes applications using the SDK to get started with other games, custom applications, and for research purposes. See our <a href="https://docs.trackir.com/trackir-sdk">documentation</a> for help getting started.</p>
            <a
              class="btn"
              @click.prevent="eulaDisplayed = true, sdkURL='https://d2mzlempwep3hb.cloudfront.net/TrackIR_SDK/TrackIR_SDK_Small_Samples_2.0.zip'"
              href=""
              >Download</a
            >
            </div>
          </div>
      </div>

    <div class="expandWrapper" :class="{ blackOut: formDisplayed }">
      <div class="section contentWrapper" :class="{ noBorder: formDisplayed }">
        <h2 v-if="!formSent || !formDisplayed">Register Your Game</h2>
        <p v-if="!formSent || !formDisplayed">
          Once completed, your game will appear in the TrackIR software list of
          games, and users will be able to set custom profiles. In addition, the
          game will be listed to the
          <router-link to="/games">TrackIR Enhanced Games list</router-link>.
        </p>

        <transition-slide>
          <div v-if="formDisplayed" class="registerForm">
            <a class="closeForm" href="" @click.prevent="formDisplayed = false"
              >X</a
            >

            <!-- Processing Form -->
            <div v-if="formSent && !userMessage" class="userMessageWrapper">
              <h2>Processing your request</h2>
              <img
                class="loadingSpinner"
                src="@/assets/images/spinner.svg"
                alt="Loading Spinner"
              />
            </div>
            <!--END Processing Form-->

            <!--Displaying user message-->
            <div
              v-if="userMessage"
              class="userMessageWrapper"
              v-html="userMessage"
            ></div>
            <!--END displaying user message -->

            <form v-if="!formSent" id="registerGameForm" @submit="checkForm">
              <fieldset>
                <legend>Game</legend>
                <div class="fullWidth">
                  <label :class="{ error: titleError }">Title*</label>
                  <input
                    :class="{ error: titleError }"
                    type="text"
                    id="title"
                    name="title"
                    v-model="title"
                    tabIndex="1"
                  />
                </div>
                <div class="left halfWidth">
                  <label :class="{ error: categoryError }">Category*</label>
                  <select
                    :class="{ error: categoryError }"
                    id="category"
                    name="category"
                    v-model="category"
                    tabIndex="2"
                  >
                    <option value="OTHER">Other</option>
                    <option value="FLIGHT">Flight</option>
                    <option value="RACING">Racing</option>
                    <option value="SPACE">Space</option>
                    <option value="FIRST_PERSON">First Person</option>
                  </select>
                </div>
                <div class="right halfWidth">
                  <label>Release Date</label>
                  <datepicker
                    tabIndex="3"
                    id="release"
                    name="release"
                    v-model="release"
                    :clear-button="true"
                  ></datepicker>
                </div>
              </fieldset>
              <fieldset>
                <legend>Contact</legend>
                <div class="fullWidth">
                  <label :class="{ error: nameError }">Name*</label>
                  <input
                    :class="{ error: nameError }"
                    type="text"
                    id="name"
                    name="name"
                    v-model="name"
                    tabIndex="4"
                  />
                </div>
                <div class="left halfWidth">
                  <label :class="{ error: emailError }">Email*</label>
                  <input
                    :class="{ error: emailError }"
                    type="email"
                    id="email"
                    name="email"
                    v-model="email"
                    tabIndex="5"
                  />
                </div>
                <div class="right halfWidth">
                  <label>Company</label>
                  <input
                    type="text"
                    id="company"
                    name="company"
                    v-model="company"
                    tabIndex="6"
                  />
                </div>
              </fieldset>
              <div class="fullWidth">
                <input class="hide" id="genre" type="text" v-model="genre" />
                <input id="submitBtn" type="submit" value="Submit" class="submit btn" />
              </div>
            </form>
          </div>
        </transition-slide>
        <a
          v-if="!formDisplayed"
          id="displayRegisterFormTrigger"
          href=""
          @click.prevent="formDisplayed = true"
          class="btn"
          >Register Now</a
        >
      </div>
    </div>
    <modal id="eulaModal" :isDisplayed="eulaDisplayed" :downloadURL="sdkURL" v-on:modal-close="eulaDisplayed=false">
      <sdk-eula theme='black'></sdk-eula>
    </modal>
  </div>
  <!-- END developers -->
  
</template>
<script>
import axios from "axios";
import TransitionSlide from "@/components/Transitions/TransitionSlide";
import Datepicker from "vuejs-datepicker";
import Modal from "@/components/Layouts/Modal";
import SdkEula from "@/views/SdkEula";
import { IMAGES_URL } from "@/constants/";
export default {
  name: "developers",
  computed: {
    imagesUrl: function () {
      return IMAGES_URL;
    },
  },
  data() {
    return {
      formDisplayed: false,
      formSent: false,
      titleError: false,
      categoryError: false,
      nameError: false,
      emailError: false,
      title: null,
      category: null,
      release: null,
      name: null,
      email: null,
      company: null,
      genre: "THISTITLEISAFUNGAME",
      userMessage: null,
      eulaDisplayed: false,
      sdkURL: null,
    };
  },
  components: {
    Datepicker,
    SdkEula,
    Modal,
    TransitionSlide,
  },
  methods: {
    checkForm: function (e) {
      /* Check if all form inputs have values */
      if (this.title && this.category && this.name && this.email) {
        this.sendForm(e);
        e.preventDefault();
        return true;
      }

      /* Reset Errors */
      this.titleError = false;
      this.categoryError = false;
      this.nameError = false;
      this.emailError = false;

      /* Check for missing input data */
      if (!this.title) {
        this.titleError = true;
      }
      if (!this.category) {
        this.categoryError = true;
      }
      if (!this.name) {
        this.nameError = true;
      }
      if (!this.email) {
        this.emailError = true;
      }
      /* prevent sending form */
      e.preventDefault();
    },
    sendForm: function (e) {
      //console.log("api root = " + process.env.VUE_APP_API_ROOT);
      this.formSent = true;

      axios
        .post(process.env.VUE_APP_API_ROOT + "games/new_registration", {
          id: 0,
          title: this.title,
          category: this.category,
          releaseDate: new Date(this.release).getTime(),
          name: this.name,
          email: this.email,
          company: this.company,
          genre: this.genre,
        })
        .then((response) => {
          /* Success */
          this.output = response.data;
          this.userMessage =
            "<h2>Thank you <br>For registering your game.</h2> <p>We will contact you shortly.</p>";
        })
        .catch(function (error) {
          /* Error */
          //console.log(error);
        });

      e.preventDefault();
    },
    resetForm: function () {
      (this.titleError = false),
        (this.categoryError = false),
        (this.nameError = false),
        (this.emailError = false),
        (this.title = null),
        (this.category = null),
        (this.release = null),
        (this.name = null),
        (this.email = null),
        (this.company = null),
        (this.genre = "THISTITLEISAFUNGAME");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
.developers {
  text-align: center;
  padding-bottom: 100px;

  .twoWide {
    text-align: left;
  }
}
h1 {
  margin-bottom: 40px;
  padding-bottom: 0px;
}
h2 {
  margin: 0px;
}
h3 {
  margin-top: 0px;
  padding-top: 0px;
  font-weight: 700;
}
a {
  text-decoration: underline;
  color: #ffffff;
}

.expandWrapper {
  position: relative;
  transition: all 1s;
  background-color: transparent;
}
.expandWrapper.blackOut {
  background-color: #161616;
  color: rgba(255, 255, 255, 0.5);
}

.closeForm {
  position: absolute;
  top: 40px;
  right: 40px;
  text-decoration: none;
  font-weight: 100;
  font-family: $font-stack-heading;
  font-size: 24px;
  color: rgba(255, 255, 255, 0.5);

  &:hover {
    color: #ffffff;
  }
}

.userMessageWrapper {
  padding: 20px 0 40px;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .loadingSpinner {
    margin: 40px auto;
    max-width: 150px;
  }
  h2 {
    margin-bottom: 20px;
  }
}

.btn {
  margin-top: 30px;
  margin-bottom: 50px;
  color: inherit;
  text-decoration: inherit;

  &.submit {
    color: var(--color-highlight);
    border: 1px solid var(--color-highlight);
    background-color: transparent;
    outline: none;

    &:hover {
      cursor: pointer;
      background-color: rgba(255, 255, 255, 0.1);
    }
  }
}
.section {
  padding: 75px 0 0;
}
.hide {
  display: none;
}

form {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .userMessage {
    color: #ffa600;
    height: 40px;
    margin-top: -40px;
  }

  p {
    text-align: left;
  }

  input[type="text"],
  input[type="email"] {
    box-sizing: border-box;
    font-size: 13px;
    padding: 4px 10px;
    border: solid 1px rgba(255, 255, 255, 0.5);
    border-radius: 6px;
    width: 100%;
    background: transparent;
    color: rgba(255, 255, 255, 0.5);
    outline: none;
    margin-bottom: 20px;

    &.danger,
    &.error {
      border-color: var(--color-danger);
    }
  }
  select {
    box-sizing: border-box;
    font-size: 13px;
    padding: 3px 10px;
    width: 100%;
    border-radius: 6px;
    border: solid 1px rgba(255, 255, 255, 0.5);
    background: transparent;
    color: rgba(255, 255, 255, 0.5);
    outline: none;
    margin-bottom: 20px;

    &.danger,
    &.error {
      border-color: var(--color-danger);
    }
    option {
      background-color: #161616;
    }
  }
}

.errorText {
  box-sizing: border-box;
  height: 20px;
  color: var(--color-danger);
  margin-top: 5px;
  margin-bottom: 15px;
  margin-bottom: 0px;
  font-size: 10pt;
  background-color: #575757;
}

fieldset {
  margin: 30px 0 0;
  border-top: 1px solid #ffffff;
  border-right: none;
  border-bottom: none;
  border-left: none;
  padding: 10px 0px;
  box-sizing: border-box;
  text-align: left;
  width: calc(50% - 15px);

  .halfWidth {
    display: inline-block;
    box-sizing: border-box;
    width: calc(50% - 15px);
    &.left {
      margin-right: 15px;
    }
    &.right {
      margin-left: 15px;
    }
  }
  .fullWidth {
    width: 100%;
    box-sizing: border-box;
  }

  &:nth-child(1) {
    margin-right: 15px;
  }
  &:nth-child(2) {
    margin-left: 15px;
  }

  legend {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    font-size: 18px;
    font-weight: bold;
    color: #ffffff;
    padding: 0px 20px 0px 20px;
  }
  label {
    display: block;
    width: 100px;
    padding: 0px 0px 8px;
    color: #ffffff;

    &.error {
      color: var(--color-danger);
    }
  }
}
.fullWidth {
  width: 100%;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

/* Datepicker */
::v-deep .vdp-datepicker {
  input {
    box-sizing: border-box;
    font-size: 13px;
    padding: 4px 10px;
    border: solid 1px rgba(255, 255, 255, 0.5);
    border-radius: 6px;
    width: 100%;
    background: transparent;
    color: rgba(255, 255, 255, 0.5);
    outline: none;
    margin-bottom: 20px;
  }
  .vdp-datepicker__clear-button {
    position: absolute;
    top: -28px;
    right: 5px;
  }
  .vdp-datepicker__calendar {
    color: #161616;
    width: 200px;
    font-size: 12px;
    padding: 10px;
    border-radius: 6px;

    header {
      line-height: 20px;
    }
    .cell {
      padding: 0 2px;
      height: 20px;
      line-height: 20px;

      &.selected {
        background-color: #ffa600;
        color: #ffffff;
      }
    }
    .cell.day:hover {
      border: 1px solid #ffa600;
    }
  }
}
.sdkLogo {
  max-width: 200px;
  opacity: 0.6;
}

.introSection {
  padding-bottom: 40px;
}
.sdkVersionWrapper {
    margin-bottom: 20px;

  .contentWrapper {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 40px;
    row-gap: 20px;
    align-items: center;
    text-align: center;
    padding:40px;
    border-radius: 40px;

    @include for-medium-up {
      grid-template-columns: 100px 1fr;
      text-align: left;
    }

    .visual.twoLogos {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      row-gap: 20px;
      column-gap: 20px;

      @include for-medium-up {
        flex-direction: column;
      }

      img {
        max-width: 60px;
      }

    }
    .copy {
      a.btn {
        margin:8px 0 0;
      }

    }
  }
}
</style>
