<template>
  <div class="modal" v-bind:class="{ modalHide: displayed === false }" v-on:click="$emit('modal-close')" ref="modalcontent">
    <div class="modalContent" @click.stop="" >
      <button class="closeBtn" v-on:click="$emit('modal-close')">X</button>
      <h1 v-if="title">{{ title }}</h1>
      <slot></slot>
      <div class="acceptWrapper contentWrapper"  >
        <input id="agreeCheck" type="checkbox" ref="agreecheck" v-model="accepted">
        <label for="agreeCheck">I Agree To The EULA</label>
      </div>
    </div><!-- END .modalContent --> 
  </div><!-- END .modal -->
</template>

<script>

export default {
  name: "Modal",
    data() {
    return {
      isAccepted: false,
    }
  },
  props: {
    title: { type: String },
    isDisplayed: { type: Boolean, default: false },
    downloadURL: { type: String },
  },
  computed: {
    displayed: {
      get () {
        return this.isDisplayed;
      },
      set (newValue) {      
        if(newValue === true) {

          // prevents double scrollbars when modal is displayed
          document.documentElement.style.overflow = 'hidden';
        } else {

          // prevents double scrollbars when modal is displayed
          document.documentElement.style.overflow = 'visible';

          this.$emit('modal-close', true);

          // wait for animation to finish
          setTimeout(() => {
            // set accepted back to false after animation
            this.accepted = false;

            // scroll the modal content back to the top after animation
            this.$refs.modalcontent.scrollTop=0;
           
          },1500);
        }   
      }
    },
    accepted: {
      get () {
        return this.isAccepted;
      },
      set (newValue) {
        if(newValue === true) {

          // download file
          window.location = this.downloadURL;

          // close modal
          this.displayed = false;

          // update the checkbox
          this.$refs.agreecheck.checked = true;

        } else {

          // update the checkbox
          this.$refs.agreecheck.checked = false;
        }
      }
    }
  },
  watch: {
    isDisplayed: function (newValue) {
      this.displayed = newValue;
    },
    isAccepted: function (newValue) {
      this.accepted = newValue;
    }
  },

};
</script>

<style scoped lang="scss">
@import "@/assets/styles/_variables.scss";

.modal {
  position: fixed;
  z-index: 550;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);
  opacity:1;
  transition: opacity 0.3s ease-in-out;

  &.modalHide{
    opacity:0;
    pointer-events:none;
  }
}

.modal.modalHide .modalContent {
  transform: translateY(90vh);
}

h1 {
  text-align: center;
  font-size: 45px;
}

.modalContent {
  position: relative;
  transition: transform 0.3s ease-in-out;
  border-radius:40px;
  background-color: #fefefe;
  padding: 20px;
  border: 1px solid #888;
  width: 80vw;
  max-width: 702px;
  margin:20px auto;
  box-sizing: border-box;
  text-align: left;

  .closeBtn{
    color:#000;
    padding:0;
    margin:0;
    background: transparent;
    border:0;
    font-size: 48px;
    position: absolute;
    top: 48px;
    right: 80px;

    &:hover{
      cursor: pointer;
    }
  }

  .acceptWrapper{
    color:#000;
    padding:0 0 40px;
    font-size:24px;
    color:#A60000;

    label {
      margin-left:8px;
    }
  }
  #agreeCheck{
    width:20px;
    height: 20px;
    line-height: 24px;
  }

}
</style>